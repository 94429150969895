.simple-sidebar-layout {
  .page-header {
    display: block;
    margin-bottom: 1.5rem;
  }

  .layout_content {
    background-color: #fff;
    border-radius: 3px;
    margin: 1rem 3rem;
    box-shadow: 0 2px 16px 0 rgba(39, 45, 51, 0.1);
    display: flex;
    flex-direction: row;

    .sidebar {
      order: 1;
      border-right: solid 2px rgba(16, 22, 26, 0.15);
    }

    .content {
      order: 2;
      width: 100%;
      position: relative;

      &>div {
        width: 100%;
        height: 100%;
      }

      .loading-wrapper {
        background-color: #fff;
        position: absolute;
      }
    }
  }
}

.mobile {
  .simple-sidebar-layout {
    .layout_content {
      margin: 0;
    }

    .container-single {
      padding-top: 0;
    }
  }
}
