.table {
  .table_cell--item,
  .grid-cell--title {
    padding: 0 16pt;
  }

  .table_select {
    padding-left: 8pt;
  }

  .table_cell {
    overflow: hidden;
  }

  .pos-rel {
    max-width: 100%;
  }

  .operations-displayRunwayName .pos-rel,
  .operations-displayCategory .pos-rel {
    width: 100%;
  }

  th.table_cell {
    .bp3-button.icon.icon-right,
    .bp3-button.mosaic-button.icon.icon-right {
      padding: 0;
    }
  }

  .table th.table_cell,
  .table td.table_cell {
    padding: 0 32pt 0 0;
  }

  .table th.table_cell:first-child,
  .table td.table_cell:first-child {
    padding: 0 32pt 0 16pt;
  }
}

.table-row_header {
  .table_cell {
    position: relative;

    &:hover .sort-icon {
      opacity: 1;
    }
  }

  .sort-header {
    line-height: normal;
    height: auto;
    min-height: auto;
    border-radius: $border-radius;
    position: relative;

    span {
      line-height: normal;
      display: flex;
      align-items: center;
    }

    .hover-only {
      opacity: 0;
    }

    &.bp3-button {
      padding: 0;
      height: 1.5rem !important;
      min-width: unset;

      &.has-abbreviation {
        width: auto;
        padding-right: 20px;

        & .bp3-button-text {
          width: 100%;
          line-height: 1.5em !important;
          height: 1.5em !important;
        }

        & .overflower-short,
        & .overflower-long {
          justify-content: center;
          line-height: 1.5em;
        }
      }

      & .bp3-button-text,
      & .bp3-button-text span {
        line-height: normal;
        align-items: center;
      }

      &:hover {
        svg {
          opacity: 1;
        }

        .hover-only {
          opacity: 1;
        }
      }

      &:active {
        color: $brand-01;

        svg {
          fill: $brand-01;
        }
      }
    }

    &:focus {
      svg {
        opacity: 1;
      }
    }

    &--loading,
    &--loading:hover,
    &--loading.bp3-button:hover {
      color: $ui-04;
    }
  }

  span.sort-header.has-abbreviation {
    display: block;
    padding: 0 8pt;
  }

  .sort-icon {
    position: absolute;
    left: calc(100% - 20px);
    top: 3px;
  }

  .disabled {
    opacity: 0.45 !important;
  }
}
