.correlatedCollapse {
  padding: 8px 15px;
  margin-bottom: 14px;

  &__header {
    display: flex;
  }

  &__header-name,
  &__header-data {
    flex-basis: 30%;
    display: flex;
    align-items: center;
  }

  &__header-name,
  &__header-data:first-child {
    flex-basis: 40%;
    min-width: 0;
    padding-right: 5px;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__header-name:last-child,
  &__header-data:last-child {
    justify-content: end;
  }

  &__header-name {
    font-size: 16px;
    font-weight: 600;
    color: $brand-darkened;
    text-decoration: none;
  }

  &__header-link {
    span[class*='-icon'],
    svg[class*='-icon'] {
      width: 16px;
      height: 16px;
    }
  }

  &__header-data {
    font-size: 14px;
    color: #637381;
  }
}

/* Hiding class, making content visible only to screen readers but not visually */
/* "sr" meaning "screen-reader" */

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
