.scenarioGeneration-container {
  .scenarioGeneration_header {
    margin-bottom: 1.5rem;

    .page-header {
      .bp3-popover-target button {
        padding: 0;
      }

      .page-tools {
        .scenarioGeneration_dateRange.hasError .bp3-button {
          color: $error-01;
        }
        .scenarioGeneration_dateRange.hasError .mosaic-icon {
          fill: $error-01;
        }
      }
    }
  }
  .scenarioGeneration_content {
    margin: 0rem 2.5rem;
    padding: 0 8px;
  }
  .scenarioGeneration_card_title {
    margin-bottom: 16px;
  }

  .card > div.scenarioGeneration_content_pad {
    padding: 1rem 1rem 2rem 3rem;
  }

  .load-more-bar,
  .end-of-table {
    margin-bottom: 50px;
  }

  .noise-scenario-table-dayCount,
  .noise-scenario-table-eveningCount,
  .noise-scenario-table-nightCount {
    justify-content: flex-end;
    text-align: right;
  }
}

.noise-scenario-table {
  background: $white;
  grid-template-columns:
    [selector] 64px
    [aircraftType] minmax(200px, max-content)
    [stage] 80px
    [route] minmax(100px, max-content)
    [runway] 90px
    [operation] 110px
    [day] minmax(95px, 110px)
    [evening] minmax(95px, 110px)
    [night] minmax(95px, 110px)
    [spacer] minmax(100px, 1fr);

  .noise-scenario-table-endSpacer {
    pointer-events: none;
  }

  .table-row_header {
    .table_cell,
    .table_select {
      background: $ui-02;
    }
  }

  .table-row__hasError {
    .table_select,
    .table_cell {
      height: 63px;
      padding-bottom: 12px;
    }
  }

  .table_cell {
    &.noise-scenario-table-stageLength {
      overflow: initial;
    }
  }
}
