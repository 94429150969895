.aircraft-noise-contours-settings__container {
  .settings__heading {
    //margin: -2.5rem;
    margin-bottom: 0;
    padding: 2.5rem 2.5rem 0 2.5rem;
  }

  .aircraft-noise-contours-table {
    grid-template-columns:
      [selector] 64px
      [name] minmax(150px, max-content)
      [noiseModelType] 70px
      [style] minmax(max-content, 100px)
      [createTime] 120px
      [delete] minmax(64px, 1fr);

    .table-cell__link {
      color: $brand-darkened;
      text-decoration: underline;
    }
  }
}

// Icons
.aircraft-noise-contours-table {
  .aircraft-noise-contours-table-numberOfFlights {
    justify-content: flex-end;
    text-align: right;
  }
  .aircraft-noise-contours-table-delete {
    justify-content: flex-end;
  }

  .table-icon__source {
    color: $ui-04;
    pointer-events: none;

    .mosaic-button.standard {
      min-width: unset;
      svg {
        color: $ui-04;
        fill: $ui-04;
      }

      &:hover {
        background: transparent;
      }
    }
  }
}

.aircraft-noise-contours-modal {
  width: 748px;
}
