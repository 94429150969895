.tim-info {
  @extend .font-styles;
  font-weight: 600;
  color: $text-01;
}

.tim-default-info {
  @extend .font-styles;
  line-height: $spacing-lg;
  color: $text-01;
  height: $layout-xs;
  margin-top: $spacing-xs;
}

.tim-input-bar {
  margin-top: $spacing-xs;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.config-element {
  display: flex;
  flex-direction: column;
  margin-top: $spacing-md;
  margin-right: $spacing-md;

  &__label {
    @extend .font-styles;
    height: $layout-xs;
    color: $text-01;
  }

  &__input {
    background: $ui-01;
    box-sizing: border-box;
    border-radius: 3px;
    width: 160px;
  }

  &__postfix {
    @extend .font-styles;
    line-height: $spacing-md;
    color: $text-01;
    margin: $spacing-xs;
  }

  &__field {
    display: flex;
    margin: $spacing-3xs;
    height: auto;
  }
}

.tim-action-element {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: $spacing-md;
  margin-bottom: $spacing-md;
  flex-wrap: wrap;
}

.tim-each-runway {
  @extend .font-styles;
  height: $layout-xs;
  line-height: 34px;
  font-weight: 600;
}

.time-in-modes-table {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}
