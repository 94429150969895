@import 'vars.module.scss';
$map-skeleton-bg: $ui-03 !default;

.noise-events-table {
  .rule_link {
    color: $brand-darkened;
    text-decoration: none;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .hover-only {
    opacity: 0;
  }

  .cell-type {
    &--location-name {
      font-weight: bold;
    }

    &--aircraft,
    &--duration {
      text-align: center;
      display: flex;
      justify-content: center;

      & .grid-cell--title {
        overflow: hidden;
        justify-content: center;
      }

      & .grid-cell--item {
        text-align: center;
        transform: translateX(-30%);
      }
    }

    &--duration {
      text-align: left;
      justify-content: left;

      & .grid-cell--title {
        justify-content: left;
      }

      & .grid-cell--item {
        justify-content: left;
      }

      .has-abbreviation {
        .overflower-short,
        .overflower-long {
          justify-content: left !important;
        }
      }
    }

    &--time {
      color: $time-color;

      .table_cell--item {
        width: 100%;
      }
    }
  }

  &.table--loading .table_cell:hover .hover-only {
    opacity: 0;
  }

  button .overflower {
    padding: 0;
  }

  .table_cell--item {
    button {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.implausible-events-table {
  .table_cell--item {
    button {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .rule_link {
    color: $brand-darkened;
    text-decoration: none;

    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .hover-only {
    opacity: 0;
  }

  .cell-type {
    &--location-name {
      font-weight: bold;
    }

    &--aircraft,
    &--duration {
      text-align: center;
      display: flex;
      justify-content: center;

      & .grid-cell--title {
        overflow: hidden;
      }

      & .grid-cell--item {
        text-align: center;
        transform: translateX(-30%);
      }
    }

    &--duration {
      text-align: left;
      justify-content: left;

      & .grid-cell--title {
        justify-content: left;
      }

      & .grid-cell--item {
        justify-content: left;
      }

      .has-abbreviation {
        .overflower-short,
        .overflower-long {
          justify-content: left !important;
        }
      }
    }

    &--time {
      color: $time-color;

      .table_cell--item {
        width: 100%;
      }
    }
  }

  &.table--loading .table_cell:hover .hover-only {
    opacity: 0;
  }
}

.noise-events-table,
.implausible-events-table {
  .noiseevents-maxLevel .pos-rel,
  .noiseevents-sel .pos-rel,
  .noiseevents-duration .pos-rel {
    width: 100%;
    padding-left: 16pt;
  }

  .noiseevents-displayLocationName span.table_cell--item,
  .noiseevents-displayTime span.table_cell--item {
    max-width: 100%;
  }

  .noiseevents-displayCategory .pos-rel {
    width: 100%;
  }

  .table-row_header .noiseevents-displayCategory .sort-icon {
    left: 100%;
  }

  .has-abbreviation .sort-icon {
    left: 100%;
  }
}

.map_placeholder {
  height: 28rem;
  background-color: #eef0f2;
}

.noise-graph_container {
  visibility: visible;
  height: 72px;

  .chart {
    position: relative;
  }

  &--full {
    height: auto;
  }

  &--hide {
    visibility: hidden;
    height: 0px;
  }

  &--error {
    height: 72px;
    background-color: $ui-01;
    opacity: 0.74;

    font-size: 16px;
    color: $text-01;
    font-weight: 400;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--nmt {
    position: absolute;
    top: $spacing-3xs;
    right: 24px;
  }

  &--nmt-title {
    display: inline-flex;
    align-items: center;
    line-height: 20px;
    font-family: $font-family-sans-serif;
    color: $ui-05;
    font-weight: 500 !important;
    height: 26px;
    margin-right: $spacing-3xs;
    font-size: 14px;
  }

  &--nmt-label {
    display: inline-flex;
    align-items: center;
    line-height: 20px;
    font-family: $font-family-sans-serif;
    color: $text-01;
    font-weight: 600 !important;
    height: 26px;
    margin-right: 3px;
    font-size: 14px;
  }
}
