$ui-01: #ffffff;
$ui-05: #5e6872;
$text-01: #2e384d;
$shadow-02: 0 2px 16px 0 rgba(39, 45, 51, 0.1);
$spacing-baseline: 1rem !default;
$spacing-md: $spacing-baseline !default;
$spacing-sm: $spacing-baseline * 0.75 !default;
$spacing-3xs: $spacing-baseline * 0.125 !default;

.location-popup {
  .mapboxgl-popup-content {
    background-color: $ui-01 !important;
    padding: $spacing-sm !important;
    box-shadow: $shadow-02;
  }

  .location-popup {
    &_title,
    &_value {
      font-size: 0.75rem;
      font-weight: 500;
      color: $text-01;
    }

    &_title {
      padding-bottom: $spacing-sm;
    }

    &_label {
      font-size: 0.65rem;
      font-weight: 400;
      padding-bottom: $spacing-3xs;
      color: $ui-05;
    }

    &_row {
      font-size: 1rem;
      display: flex;
      justify-content: space-between;
    }

    &_col {
      display: flex;
      flex-direction: column;
    }
  }
}

.location-filter-button {
  display: flex;
  justify-content: center;
  margin-top: $spacing-md;
}
