.airtrak-single-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0rem $spacing-3xl;
  flex: auto;
}

.airtrak-progressbar-card {
  margin-top: $spacing-xs;
}

.airtrak-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: $spacing-md 0rem;
  flex-wrap: wrap;
}

.airtrak-progressbar {
  display: flex;
  flex-wrap: wrap;

  &__bar {
    margin: $spacing-xs $spacing-md;
    width: 25%;
  }
}

.airtrak-progressbar-text {
  margin: 0rem $spacing-xs;
  font-size: $spacing-sm;
}

.header-left-items {
  display: flex;
  flex-wrap: wrap;
}

.header-right-items {
  display: flex;
  flex-wrap: wrap;
}

.emission-summary-item {
  height: $layout-2xs;
  font-weight: 400;
  font-size: $spacing-sm;
  line-height: $spacing-xl;
  margin: 0rem $spacing-sm;
}

.page-header-dropdown {
  .page-header {
    padding-right: 0px;
    padding-left: 0px;
  }
}

.emission-summary-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $spacing-xs;
}

.airtrak-sub-header {
  display: flex;
  margin-bottom: $spacing-md;
  flex-wrap: wrap;
}

.view-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-right: $spacing-3xl;
  height: $layout-md;
}

.view-button {
  width: $spacing-2xl;
  height: $layout-sm !important;
  border-radius: 0 !important;
  border: $spacing-4xs solid $ui-03 !important;
  background: $ui-01 !important;

  &:first-child {
    border-top-left-radius: $spacing-3xs !important;
    border-bottom-left-radius: $spacing-3xs !important;
  }

  &:last-child {
    border-top-right-radius: $spacing-3xs !important;
    border-bottom-right-radius: $spacing-3xs !important;
  }

  &:first-child ~ & {
    border-left: none !important;
  }
}

.view-active-button {
  color: $ui-01 !important;
  background: $brand-darkened !important;
}

.breakdown-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: $layout-md;
}

.breakdown-button {
  width: $layout-xl !important;
  height: $layout-sm !important;
  border-radius: 0px !important;
  border: $spacing-4xs solid $ui-03 !important;
  background: $ui-01 !important;

  &:first-child {
    border-top-left-radius: $spacing-3xs !important;
    border-bottom-left-radius: $spacing-3xs !important;
  }

  &:last-child {
    border-top-right-radius: $spacing-3xs !important;
    border-bottom-right-radius: $spacing-3xs !important;
  }

  &:first-child ~ & {
    border-left: none !important;
  }
}

.active-button {
  color: $ui-01 !important;
  background: $brand-darkened !important;
}

.airtrak-breakdown-title {
  margin-right: $spacing-md;
  font-weight: 500;
  font-size: $spacing-md;
  line-height: $spacing-lg;
  color: $text-02;
}

.airtrak-single-container {
  .filter-row-container {
    padding: 0rem;
  }
}

.table_wrapper-airtrak-data {
  overflow-y: scroll;
  position: relative;
  height: 100%;
  overflow: auto;
}

.table_wrapper-airtrak-footer {
  &::-webkit-scrollbar {
    visibility: hidden;
  }
}

.airtrak-table_container {
  overflow: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex: auto;
  .table_wrapper-airtrak-data.table_wrapper-airtrak-data {
    .table_wrapper {
      padding: 0rem;
    }
  }
  .table_wrapper-airtrak-footer.table_wrapper-airtrak-footer {
    .table_wrapper {
      //  This padding is to make the footer cells line up with
      //  the main table cells when a scrollbar is present
      padding: 0 1.2rem 0 0;
    }
  }
}

.table.airtrak-regular-table {
  .table_cell--item,
  .grid-cell--title {
    padding: 0;
  }

  tr {
    display: flex;
    flex: 1;
  }

  th,
  td {
    display: flex;
    flex: 1;
  }

  .airtrak-date,
  .airtrak-total {
    padding-left: 1rem;
  }

  .airtrak-Combined {
    justify-items: unset;
  }

  &.table--loading .table_cell:hover .hover-only {
    opacity: 0;
  }
}

.airtrak-graph-container {
  flex: auto;
  padding: 0px $spacing-3xl 0px $spacing-3xl;
}

.spinner-loading {
  @extend .loading-wrapper;
  margin-top: 25vh;
}

.filter-row-container,
.filter-popover-content,
.highcharts-container {
  .subscript {
    vertical-align: sub;
    font-size: smaller;
  }
}
