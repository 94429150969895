.locationmanagement-container {
  .locationmanagement-table {
    grid-template-columns:
      [select] 64px
      [name] minmax(max-content, 150px)
      [location] minmax(100px, 1fr)
      [nmtModel]minmax(max-content, 100px)
      [lastData]minmax(max-content, 100px)
      [isActive] 100px;

    .grid-cell--title {
      padding: 0;
    }
    .sort-icon {
      position: relative;
      left: 3px;
    }
  }
  .table_row.table-row_header .table_cell,
  .table_row.table-row_item .table_cell--item {
    padding: 0;
    flex-direction: row;
    width: 100%;
  }
  .table_cell--item {
    display: block;
    flex-direction: row;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .table_cell--item:has(.mosaic-skeleton-text) {
    text-overflow: clip;
  }

  .locationmanagement-table-isActiveCheckMark {
    .table_cell--item {
      display: flex;
    }
  }

  .locationmanagement--link {
    cursor: pointer;
    font-weight: bold;
    text-overflow: ellipsis;
    color: $brand-darkened;
    overflow: hidden;
  }
}
.locationmanagement-table-isActiveCheckMark {
  .table_cell--item {
    margin: 0 auto;
    font-weight: bold;
    font-size: 1.2em;
    width: 100%;
    padding: 0;
    margin: 0 auto;
    justify-content: space-around;
    margin-left: -0.25rem;
  }
}

.locationtemplate__table-wrapper {
  margin: -1.3rem -2.8rem;
  .table_row.table-row_item--hover:hover td {
    background-color: white;
  }
  .locationtemplate__table {
    grid-template-columns:
      [time] auto
      [threshold] auto
      [minDuration] auto
      [maxDuration] auto
      [endDuration] auto;
  }
  .locationtemplate__table_edit {
    grid-template-columns:
      [time] auto
      [threshold] auto
      [minDuration] auto
      [maxDuration] auto
      [endDuration] auto
      [deleteRow]minmax(0, 64px);
  }
  .table_row.table-row_header .table_cell,
  .table_row.table-row_item .table_cell--item {
    padding: 16px;
    flex-direction: row;
    width: 100%;
  }
  .location-settings__field-error,
  .no-search-data-image {
    display: none;
  }
}

.grid-no-data {
  height: auto;
  padding: 1rem;
}

.timeinput-container .timeinput .text-input_field {
  width: 68px;
}

.table-row_header {
  .pos-rel {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
