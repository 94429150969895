.map-settings_panel {
  .backgrounds-panel,
  .layers-panel {
    padding: 0 12px;

    border-right: 1px solid #8897a2;

    &:last-of-type {
      border-right: none;
    }
  }

  .backgrounds-panel_title,
  .layers-panel_title {
    text-transform: uppercase;
    text-decoration: none;
    font-weight: normal;
  }

  .backgrounds-panel_label,
  .layer-panel_label {
    font-weight: normal;
  }

  .backgrounds-panel .track-style,
  .backgrounds-panel .track-density {
    .backgrounds-panel_image {
      width: 70px;
      height: 70px;
      flex-shrink: 0;
    }
  }

  .track-density h3.backgrounds-panel_title {
    padding-top: 1rem;
  }
}
