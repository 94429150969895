.page-header {
  font-weight: normal;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 $layout-md;

  .page-wrapper--landscape & {
    @include respond-above(lg) {
      padding: 0 0 0 $layout-md;
      &.page-header_navigation {
        padding: 0 $layout-md;
      }
      &.page-header_summary {
        padding: 0 $layout-md $layout-xs $layout-md;
      }
      &.page-header_details {
        padding: 0 $layout-md $layout-md $layout-md;
      }
      &-text {
        margin-right: 0.4rem;
      }

      .page-tools {
        &_button {
          & span {
            white-space: nowrap;
          }
        }
      }
    }
  }

  &-text {
    font-size: $layout-xs;
    display: inline-block;
    margin-right: $layout-xs;
  }

  &-button {
    padding: 0 $layout-md;
    margin-bottom: $spacing-xs;

    button {
      padding: 0 !important;
    }
  }

  > * {
    align-self: center;
  }

  &-description {
    padding: 15px 0;
  }

  :last-child:not(:first-child) {
    margin-left: auto;
  }

  .dropdown-button {
    margin-right: $spacing-xs;
  }

  &_summary {
    @include content-container;

    .dropdown-button {
      margin-right: 0;
    }

    padding: {
      left: 2rem;
      right: 2rem;
      bottom: $layout-md;
    }

    margin: 0 auto;
    z-index: 1;
  }

  &_details {
    @include content-container;

    margin: 0 auto;
    margin-top: $spacing-sm;

    padding: {
      left: 2rem;
      right: 2rem;
      bottom: $layout-md;
    }

    &-button {
      margin-left: $spacing-2xs;

      & .bp3-button {
        padding: 0 !important;
      }
    }
  }

  &_navigation {
    @include content-container;

    padding: {
      left: 2rem;
      right: 2rem;
    }

    margin: 0 auto;
    z-index: 1;
  }

  &_title {
    font-size: 28px;
    font-weight: 600;
    color: #212b36;
    margin-left: $spacing-2xs;
  }

  &_subtitle {
    @include font-size('16');
    color: $text-01;
    margin-left: $spacing-2xs !important;
    margin-top: $spacing-sm;
  }

  .page-tools {
    display: flex;

    &_button {
      margin-right: $spacing-xs;
    }
  }

  .page-tools-summary {
    display: flex;
    padding-right: 20px;

    .dropdown-button {
      margin-right: $spacing-md;
    }

    &.noise {
      padding-right: 0;

      .dropdown-button {
        margin-right: 0;
      }
    }

    button {
      height: 34px;
    }
  }
}
