$map-height: 28em !default;
$map-height-fullscreen: calc(100vh - 2rem);

// 1. fixed position doesn't take into account the nav bar
// As such, we need to subtract 40% of the nav bar's width to take into account the 40% width we have.
$map-landscape-width: calc(40% - 0.4 * 4.5rem); // [1]

/*
  $layout-pull
  This is used to give our columns auto
  margins when they stack, because we
  don't use media queries we can't
  control exactly when the margins
  are added so this works dynamically
*/
$layout-pull: $layout-sm;

// This file is used to house styles that are relevant to multiple screens.

.header-container {
  margin-bottom: $layout-xs;
}

.split-row {
  display: flex;
  justify-content: space-between;
  padding: 0 $layout-md;
}

.row-element {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 $layout-md;

  &.button {
    margin-top: $layout-xs;
  }
}

.table_wrapper {
  overflow-x: hidden;
}

.page-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  &.page-wrapper--landscape {
    @include respond-above(lg) {
      flex-direction: row-reverse;
    }

    .floating-action-button {
      position: absolute;
      bottom: calc(100vh - 31rem);
    }

    @include respond-above(lg) {
      .floating-action-button {
        position: fixed;
        bottom: 0px;
        /**
          This is a hack to use css functions that has sass name conflicts
         **/
        left: unquote('min(calc(60% - 1rem), 1175px)');
      }
    }
  }

  .filter-row-container {
    padding: 0 $layout-md;
  }

  &:not(.page-wrapper--landscape):not(.table--slim) {
    .table_wrapper {
      padding: 0 $layout-md;
    }
  }

  &--fullscreen-grid {
    flex-direction: row;
  }
}

.map_wrapper {
  max-height: $map-height;
  height: 100%;
  flex: 1;
  width: 100%;
  position: relative;

  &.noise {
    background-color: '#cbd2d3';
  }

  .map {
    height: $map-height;
  }

  .map-frame {
    height: $map-height;
    position: relative;
  }

  .circle-button {
    position: absolute;
  }

  .mode-3d,
  .dark-mode {
    position: absolute;
    bottom: $spacing-sm;
    right: $spacing-sm;
  }

  .map-overlay-panel {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;

    @include respond-above(lg) {
      .page-wrapper--landscape & {
        position: fixed;
        right: 0;
        bottom: 0;
        left: unset;
        min-width: 39%;
        width: calc(100% - 1222px);
      }
    }

    &--fullscreen {
      max-height: $map-height-fullscreen;

      .map {
        height: $map-height-fullscreen;
      }

      .map-frame {
        height: $map-height-fullscreen;
      }
    }
  }

  &--fullscreen {
    max-height: $map-height-fullscreen;

    .map {
      height: $map-height-fullscreen;
    }

    .map-frame {
      height: $map-height-fullscreen;
    }
  }

  .mode-playback {
    position: absolute;
    bottom: 0px;

    border: none;
    padding: 0;
    z-index: 2;
    margin: 0 auto;
    height: 36px;
    width: 36px;
    line-height: 100%;
    background-color: transparent;
    transition: 0.2s;
    text-align: center;
    cursor: pointer;

    svg {
      filter: drop-shadow(0 1px 3px rgba(255, 255, 255, 0.3));
    }

    &:hover {
      color: lighten($black, 50%);
    }

    &--hidden {
      display: none;
    }

    @include respond-above(lg) {
      .page-wrapper--landscape & {
        position: fixed;
        left: auto;
        margin-left: $spacing-sm;
      }
    }
  }

  @include respond-above(lg) {
    .page-wrapper--landscape & {
      max-height: 100vh;
      height: 100vh;
      flex: 1;

      .circle-button {
        position: fixed;
      }
    }

    .page-wrapper--landscape & .map > div {
      // this targets the map div which has no class
      position: fixed !important;
      height: 100vh !important;
      right: 0;
      width: unquote('max(40%, calc(100% - 1150px)) !important');
    }
  }
}

.mapboxgl-ctrl-bottom-left {
  .mapboxgl-ctrl {
    display: none !important;
  }
}

.mapboxgl-ctrl-search {
  width: 300px;
  height: 32px;
}

.navigation-control {
  .mapboxgl-ctrl-group {
    &.mapboxgl-ctrl-group {
      margin-bottom: 0.375rem;
    }
  }
}

#LineGraph {
  width: 100% !important;
}

.container-fluid {
  width: 100%;
  display: block;
  flex-shrink: 0;
  z-index: 2;
  flex-direction: column;
  background-color: $ui-02;
  padding-top: $layout-sm;

  &--details {
    padding-top: 1rem;
  }

  &--inner {
    padding-bottom: 12rem;
    background-color: $ui-02;
  }

  .page-wrapper--landscape & {
    padding-top: 2rem;

    @include respond-above(lg) {
      max-width: 1150px;
      width: 60%;
    }
  }
}

.page-wrapper--fullscreen--grid {
  .container-fluid {
    padding-top: calc(2rem + #{$layout-sm});
  }
}

.container-single {
  width: 100%;
  display: block;
  flex-shrink: 0;
  z-index: 2;
  flex-direction: column;
  background-color: $ui-02;
  padding-top: $layout-sm;

  &--details {
    padding-top: 1rem;
  }

  &--inner {
    padding-bottom: 12rem;
  }

  .table_wrapper {
    padding: 0 $layout-md !important;
  }
}

@mixin content-container {
  max-width: 80rem;
}

.layout {
  &_single {
    @include content-container;
    width: 100%;
    margin: -$layout-pull auto;
    padding: 0 2px;

    .page-wrapper--landscape & {
      padding: 0 $layout-md;
    }
  }

  &_split {
    @include content-container;
    display: flex;
    width: 100%;
    flex-flow: wrap;
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
    z-index: 0;

    &--half {
      flex: 3 3 36rem;
      border-radius: 5px;
      min-width: 51%;
      max-width: 100%;
      margin-top: $layout-pull;

      & + & {
        flex: 2 2 26rem;
        border-radius: 5px;
        min-width: 0%;
        max-width: 100%;
      }
    }
  }

  &_content {
    margin: 0 $layout-md;
  }
}

.block {
  & + & {
    margin-top: $layout-pull;
  }
}

.table {
  overflow-x: hidden;
}
