$spacing-baseline: 1rem;
$spacing-2xs: $spacing-baseline * 0.25;
$spacing-xs: $spacing-baseline * 0.5;

.ops-popup {
  &_title {
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &_icons {
    display: flex;
    align-items: center;
    height: 1rem;
    svg[class^='ad'] {
      margin-right: -(5px);
    }
  }
  p {
    font-size: 14px;
    margin-bottom: $spacing-2xs;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &_label {
    min-width: 4rem;
    display: inline-block;
  }
  &_value {
    font-weight: 500;
  }
  &_airports {
    svg {
      margin-bottom: -6px;
    }
  }
}
