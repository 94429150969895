.map-mode--light {
  .#{$prefix}-map-popup {
    &.amsl-popup {
      line-height: 1;
      width: auto;
      border-radius: 3px;
      box-shadow: $shadow-01;

      .#{$prefix}-skeleton-text {
        height: 12px;
      }

      .mapboxgl-popup {
        .mapboxgl-popup-content {
          @include font-size('12');
          font-family: $font-family-sans-serif;
          color: $text-01;
          padding: 0.5rem;
          line-height: 1;
          letter-spacing: 0.06px;
          background-color: $ui-01;
        }

        .amsl-popup {
          &__loading {
            display: flex;
            flex-direction: column;
            justify-content: center;

            &-spinner {
              margin: 0 auto;
              padding-bottom: 5px;
            }

            &-text {
              @include font-size('12');
            }
          }

          &_label {
            @include font-size('12');
            font-family: $font-family-sans-serif;
            min-width: 40px;
            display: inline-block;
            color: $ui-05;
            line-height: 1.25;
            letter-spacing: 0.06px;
            font-weight: 400;
          }

          &_value {
            @include font-size('12');
            font-family: $font-family-sans-serif;
            color: $text-01;
            line-height: 1.25;
            letter-spacing: 0.06px;
            font-weight: 400;
            min-width: 56px;
          }

          &_title {
            @include font-size('12');
            font-family: $font-family-sans-serif;
            display: inline-block;
            color: $text-01;
            line-height: 1.25;
            letter-spacing: 0.06px;
            font-weight: 600;

            a {
              color: $brand-darkened;
            }
          }
        }

        .distance-table_separator {
          height: 1px;
          overflow: hidden;
          background: $ui-04;
          margin: 8px 0;
        }

        .distance-table {
          margin-top: 4px;
          td {
            padding: 0;
            width: 50px;
            color: $text-01;
            text-align: center;
          }
        }
      }
    }
  }
}
