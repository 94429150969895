.infringementrules-container {
  .settings__heading {
    margin-bottom: 0;
    padding: 2.5rem 2.5rem 0 2.5rem;
  }
  .infringementrules-table {
    grid-template-columns:
      [selector] 64px
      [infringementName] minmax(100px, 1fr)
      [type] minmax(max-content, 100px)
      [lastUpdated] minmax(100px, max-content)
      [isActive] 100px;
  }
  .table_cell--item {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  // Make checkmark - centered and larger
  .infringementrulestable-isActiveFormatted {
    margin: 0 auto;
    font-weight: bold;
    font-size: 1.2em;
    width: 100%;
    .table_cell--item {
      padding: 0;
      margin: 0 auto;
      justify-content: space-around;
    }
  }

  .infringementrule-link {
    cursor: pointer;
    font-weight: bold;
    text-overflow: ellipsis;
    color: $brand-darkened;
    overflow: hidden;
  }

  .infringementrules_content {
    margin: 0rem 2.5rem;
  }

  .card > div.infringementrules_content_pad {
    padding: 1rem 1rem 2rem 3rem;
  }

  .infdetailspanel {
    .infdetailspanel-row {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .infdetailspanel-row-left {
      width: calc(50% - 1.5rem);
      vertical-align: top;
    }

    .infdetailspanel-row-right {
      width: calc(50% - 1.5rem);
      vertical-align: top;

      &.nolabel .form-text-field__label {
        opacity: 0;
      }
    }

    .inftimerange {
      display: flex;
      max-width: 280px;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      .inftimerange-input {
        margin-right: 1em;
        max-width: 5em;
      }
    }

    .range-selector-container {
      justify-content: flex-start;
      padding: 0;
      .range-selector {
        margin: 0 1.5em 0 0;
      }
      .range-selector-to {
        margin: 1.5em 1.5em 0 0;
      }
    }
    .range-selector-error {
      text-align: start;
      font-size: 14px;
      flex: 1 1;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #bd1427;
      margin-left: 4px;
    }

    .infdetailspanel-item {
      padding: 0.5rem 0;

      .mosaic-button {
        padding: 0;
      }
    }
    .infdetailspanel-weight-input {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .text-input_container,
      .text-input_field {
        width: 6em;
      }
    }
    .infdetailspanel__unit {
      display: table-cell;
      color: #5a6872;
      font-size: 12px;
      margin-left: 5px;
      line-height: 100%;
      position: relative;

      vertical-align: middle;
    }
    .filter-category-button {
      margin-left: 0;
      padding: 0;
    }

    .infdetailspanel-label,
    .infdetails-type .dropdown_label {
      font-size: 0.875rem;
      padding: 0.5rem 0;
    }

    .infdetailspanel-value {
      font-size: 1.2rem;
    }

    h5 {
      font-size: 1rem;
      padding: 0.45rem 0;
    }

    .infdetails-name {
      padding-right: 48px;
      .config-element__input {
        width: 100%;
        max-width: 280px;
      }
    }
    .infrule-filter-value {
      font-size: 0.875rem;
      line-height: 1.43;
      color: #292f33;
      white-space: pre-line;
      font-weight: 500;
    }
    .infdetails-type,
    .infdetails-name {
      padding-bottom: 1rem;
    }

    .noiseruleconfigurator-row {
      padding-top: 1.5rem;

      .mosaic-radiobox {
        display: inline-block;
        margin-right: 2rem;
      }
    }
  }
  .infdetailspanel-row {
    max-width: 100%;
    .bp3-button.bp3-minimal.standard,
    .bp3-button.mosaic-button.bp3-minimal.standard {
      height: 36px;
      width: 100%;
      max-width: 280px;
    }
  }

  .mtow .config-element__input,
  .mlw .config-element__input,
  .noiseruleconfigurator .config-element__input {
    input {
      width: 5rem;
    }
    display: inline-block;
    margin-right: 1rem;
  }

  .mosaic-icon.ad-departure {
    fill: $departure;
  }

  .mosaic-icon.ad-arrival {
    fill: $arrival;
  }

  .mosaic-icon.ad-overflight {
    fill: $overflight;
  }
  collapse-triangle-icon .mosaic-icon.ad-touchandgo {
    fill: $touch-go;
  }

  .mosaic-icon.ad-miscellaneous {
    fill: $miscellaneous;
  }

  .infrule_isactive {
    display: table;
  }

  .infrule_isactive-left {
    display: table-cell;
    padding: 0 1rem 0 0;
  }

  .infrule_isactive-right {
    display: table-cell;
  }

  .infringementrules_card_title {
    padding: 0 0 1rem 0;
  }

  .infringementrules_card_spacer {
    padding: 1rem 0;
  }

  .gatedirectionspanel {
    display: flex;
    padding-top: 1.8rem;
    flex-flow: wrap;
    .direction-radio {
      padding: 0 0.5rem;
    }
  }

  .infringementrules_buttons {
    display: flex;
    width: 100%;
    padding-top: 2rem;

    .infringementrules_buttons_left {
      flex: 1;
    }

    .infringementrules_buttons_right {
      button {
        margin-left: 1rem;
      }
    }
  }
}

.infdetailspanel-input-with-unit {
  display: flex;
}
