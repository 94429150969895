$map-skeleton-bg: $ui-03 !default;
$map-skeleton-height: $map-height !default;
$content-bg: $ui-02 !default;
$content-height: 25rem !default;

.map-skeleton {
  background-color: scale-color($map-skeleton-bg, $lightness: 50%);
  height: $map-skeleton-height;

  .page-wrapper--landscape & {
    width: 100%;
    height: $map-skeleton-height;
  }

  @include respond-above(lg) {
    .page-wrapper--landscape & {
      max-height: 100vh;
      height: 100vh;
      flex: 1;
      max-width: 40vw;
    }
  }
}

.content-skeleton {
  background-color: $content-bg;
}

.map-skeleton .spinner,
.content-skeleton .spinner {
  opacity: 0.5;
}

.skeleton-settings {
  &__wrapper {
    display: flex;
    flex-direction: row;
    height: 100vh;
    background: #f2f6ff;
  }

  &__navigation {
    background-color: white;
    border-right: 1px solid #dfe3e6;
    order: 1;
    width: 230px;
  }
}
