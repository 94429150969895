@import 'src/styles/vars.module.scss';
.pill {
  @include font-size('12');
  background-color: #f3edfd;
  color: #8a4df0;
  font-weight: 500;
  border-radius: $border-radius;
  border: 1px solid #8a4df0;
  display: inline-flex;
  align-items: center;
  padding: 0 6px;
  line-height: 2;
  text-decoration: none;
  min-height: 26px;

  margin-right: 12px;

  &:last-of-type {
    margin-right: 0;
  }

  &--infringement {
    color: #8a4df0;
    border: 1px solid #8a4df0;
    background-color: #f3edfd;
  }

  &--noiseevents {
    color: $arrival;
    border: 1px solid $arrival;
    background-color: #fdf8f5;
  }

  &--complaint {
    color: #5aaafa;
    border: 1px solid #5aaafa;
    background-color: #f4f9fe;
  }

  &--operation-tag {
    color: $brand-darkened;
    border: 1px solid $brand-darkened;
    background-color: #f4f9fe;
  }

  &--link {
    &:focus {
      outline: none;

      .pill {
        box-shadow: 0 0 0 2px #0b6bf2, 0 0 0 0 transparent;
      }
    }
  }

  .pill_text {
    margin-left: 2px;
  }
}
