.manageconfig-overlay {
  .overlay_wrapper {
    width: 80%;
  }
}

.manageconfig-popup {
  max-width: 56.25rem;
  max-height: 34.9375rem;
  width: 100%;
  height: 80%;
  margin: 0 auto;
}

.manageconfig-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.manageconfig-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-sm;
}

.manageconfig-body {
  display: flex;
  flex-grow: 1;
  border-top: $spacing-4xs solid$ui-03;
  border-bottom: $spacing-4xs solid$ui-03;
  padding: $spacing-sm $spacing-md;
}

.manageconfig-save {
  padding: $spacing-sm;
  display: flex;
  justify-content: flex-end;
}

.manageconfig-left {
  display: flex;
  flex-direction: column;
  width: 45%;
  padding-right: 2%;

  .table_wrapper {
    flex-grow: 1;
    max-height: 361px;
    overflow-y: scroll;
  }
}
.manageconfig-right {
  flex: auto;
  overflow: auto;
}

.manageconfig-buttons-row {
  margin-top: $spacing-sm;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  .mosaic-button {
    font-weight: 500;
    font-size: 14px;
    line-height: $spacing-lg;
    margin-right: $spacing-2xs;

    &:last-child {
      margin-right: 0;
    }
  }
}

.manageconfig-name {
  font-weight: normal;
  font-size: 14px;
  line-height: $spacing-lg;
  color: $text-01;
}

.manageconfig-text-box {
  height: $layout-sm;
  background: $ui-01;
  box-sizing: border-box;
  border-radius: $spacing-xs;
  margin-bottom: $spacing-xl;
}

.manageconfig-daterange-header {
  font-weight: 600;
  font-size: 14px;
  line-height: $spacing-lg;
}

.manageconfig-daterange {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &__date {
    width: 50%;
    padding: 0 10px;
  }
  &__label {
    @extend .manageconfig-name;
    margin-bottom: $spacing-3xs;
  }
  &__filter {
    height: $layout-sm;
    border: $spacing-4xs solid #8997a2;
    border-radius: $spacing-3xs;
  }
}
