.link-container {
  display: flex;
  flex-direction: column;
  margin-top: 0 !important;
}

.link-card {
  margin-top: $spacing-md;
  min-width: 40%;
}

.help-link {
  display: flex;
  margin-left: $spacing-xl;
  margin-top: $spacing-md;
  align-items: center;
}

.link-icon {
  margin-right: $spacing-sm;
}

.help-layout {
  min-width: 50%;
  max-width: 600px;

  margin-left: 3rem;
}

.product-version {
  margin-top: 20px;
  margin-bottom: 20px;
}