$ui-01: #ffffff;
$ui-05: #5e6872;
$text-01: #2e384d;
$shadow-02: 0 2px 16px 0 rgba(39, 45, 51, 0.1);
$spacing-baseline: 1rem !default;
$spacing-md: $spacing-baseline !default;
$spacing-sm: $spacing-baseline * 0.75 !default;
$spacing-xs: $spacing-baseline * 0.5 !default;
$spacing-3xs: $spacing-baseline * 0.125 !default;
$brand-01: #0b6bf2;

.inquirer-popup {
  .mapboxgl-popup-content {
    background-color: $ui-01 !important;
    padding: $spacing-sm !important;
    box-shadow: $shadow-02;
  }

  .inquirer-popup {
    &_title,
    &_value {
      font-size: 0.75rem;
      font-weight: 500;
      color: $text-01;
    }

    &_count {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      background: $brand-01;
      border: 1px solid #666;
      color: white;
      font-size: 12px;
    }

    &_address {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
    }

    &_title {
      padding-bottom: $spacing-xs;
    }

    &_label {
      font-size: 0.65rem;
      font-weight: 400;
      padding-bottom: $spacing-3xs;
      color: $ui-05;
    }

    &_row {
      font-size: 1rem;
      display: flex;
      justify-content: space-between;
    }

    &_col {
      display: flex;
      flex-direction: column;
    }
  }
}
