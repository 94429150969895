.settings {
  .settings_layout {
    margin: 1rem auto;
  }

  .settings_switch {
    padding-left: 0.75rem;
  }
}

.settings-layout-content {
  @extend .layout_content;
  flex: auto;
  overflow: auto;
}

.simple-sidebar-layout .settings-layout-content {
  margin: 0;
}

.settings-content {
  order: 2;
  width: 100%;
  position: relative;
  margin: $spacing-xl;
  flex: auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.settings-sidebar {
  order: 1;
  border-right: solid 1px #dfe3e6;
  @extend .font-styles;
  font-weight: bold;
  line-height: $spacing-lg;
  color: $text-01;
  overflow-y: scroll;
  height: 100vh;
  min-width: 230px;
  scrollbar-width: none; /* Firefox */
}

.settings-sidebar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.sidebar-parent {
  display: block;
  flex-direction: column;
  position: relative;
  width: 14.375rem;
}

.sidebar-child {
  position: relative;

  .menubar-item-content {
    margin-left: $spacing-md;
  }
}

.settings-header {
  height: $spacing-xl;
}

.side-bar-menu {
  padding-top: 0px !important;
}

.settings-header-title {
  float: left;
  width: 'fit-content';
}

.settings-button {
  @extend .font-styles;
  float: right;
}

.settings-submit {
  @extend .settings-button;
  margin-right: 3rem;
  margin-left: 1rem;
  line-height: $spacing-lg;
  color: $inverse-01;
}

.settings-discard {
  @extend .settings-button;
  line-height: $spacing-xl;
  letter-spacing: -0.154px;
  color: $text-01;
}

.font-styles {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.temperature-info {
  @extend .font-styles;
  color: $text-01;
  margin-top: $spacing-xs;
}

.bp3-control-indicator {
  margin-right: $spacing-xs !important;
  margin-left: 0px !important;
}

.break-line {
  border: 1px solid $ui-03;
  margin: 0px;
}

.tma {
  @extend .font-styles;
  color: $text-01;
  width: 70%;
  padding-top: $spacing-sm;
}

.active-config {
  @extend .font-styles;
  color: #2f394c;
  margin-bottom: $spacing-3xs;
}

.scenario-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $spacing-md;

  .dropdown {
    width: fit-content;
  }
}

.manage-config {
  background-color: lightgrey;
  font-family: arial;
  margin-left: $spacing-xl;
  height: 80%;
  border-radius: $spacing-xs;
  display: inline-block;
  color: $text-01;
  height: fit-content;
  width: auto;
}

.select-config {
  display: 'flex';
  flex-direction: 'row';
}

.settings-content {
  background: #f2f6ff;
  margin: 0;
  padding: $spacing-2xl;

  .page-header {
    padding: 0;
  }

  .settings__content-container {
    padding: 24px 46px;
    border-radius: 4px;
    box-shadow: $shadow-02;
    background: white;
  }

  .settings__heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacing-2xl;

    .page-header {
      width: auto;
      margin: 0;
    }
  }

  .settings__save {
    margin-left: auto;
  }

  .settings__save-submit {
    margin-right: 0;
  }
}

// Styling for split settings layout.

.settings-content .settings__split {
  display: flex;
  flex-direction: row;
  height: 100vh;
  margin: -$spacing-2xl; // Reset page wrapper padding

  .settings__split-content {
    max-width: 1200px;
    width: 57%;
    display: block;
  }

  .settings__split-map {
    flex-grow: 1;
    // 40.5% of screen size minus - settings sidebar
    min-width: calc((100% - 14rem) * #{$settings-map-width});
    position: fixed;
    right: 0;
  }

  .settings__heading {
    padding: $spacing-2xl $spacing-2xl 0 $spacing-2xl;
  }

  .settings__navigation {
    padding: $spacing-2xl;
    margin-bottom: -4rem;
  }
}
