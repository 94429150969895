.settings-container {
  @extend .container-single;
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
}

.fleetmix-info {
  @extend .font-styles;
  font-style: normal;
  font-weight: 600;
  line-height: $spacing-lg;
}

.airlines {
  margin-top: $spacing-sm;
  overflow-y: scroll;
  width: 220px;
  .table_wrapper {
    padding: 0px !important;
  }
}

.airlines-table {
  &.table--loading .table_cell:hover .hover-only {
    opacity: 0;
  }

  .checkbox {
    height: 2.625rem;
  }
}

.airlines-mapping-table {
  display: flex;
  overflow: auto;
  flex: auto;
  flex-direction: column;
  .table_wrapper {
    padding: 1rem 0 0 2rem !important;
  }
}

.aircraft-models-header {
  display: flex;
  margin-top: $spacing-lg;
  margin-left: $spacing-xl;
  flex-wrap: wrap;
  justify-content: space-between;
}

.aircraft-models-info {
  @extend .fleetmix-info;
  font-weight: 600;
}

.airtrak-delete-btn,
.airtrak-addnew-btn {
  margin-left: $spacing-md;
}

.fleet-mix-wrapper {
  display: grid;
  grid-template-columns: 1fr 5fr;
  height: calc(100vh - 250px);
}

.table.fleet-mix-table {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.table.airlines-table {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.manageconfig-left {
  .table.airlines-table {
    display: table;
    grid-template-columns: unset;
  }
}
