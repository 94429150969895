.settings-selector {
  &__fieldset {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  &__group {
    display: flex;
    flex-direction: row;
  }

  &__input {
    margin-left: 10px;
  }
}

.track-theme__group {
  .track-theme__icon {
    margin-top: -6px;
  }
}

.altitude-bands__fieldset {
  .altitude-bands__group {
    align-items: center;
    margin-bottom: 8px;
    font-size: 14px;
    min-height: 36px;

    .altitude-bands__colorpicker {
      width: 24px;
      height: 24px;
      border: none;
      padding: 0;
      margin: 0;
      border-radius: 3px;
      margin-right: 32px;
    }

    .altitude-bands__lowervalue {
      width: 90px;
      margin-right: 10px;

      &.error {
        color: #bd1427;
      }
    }

    .altitude-bands__uppervalue {
      margin-left: 10px;
      margin-left: 40px;
    }

    .altitude-bands__input {
      display: inline-block;
      margin-left: 0;
      margin-right: 8px;
      max-width: 90px;

      // Hide the number input carets
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type='number'] {
        -moz-appearance: textfield;
      }
    }
  }

  .altitude-bands__controls {
    margin-top: 7px;
    display: flex;
    justify-content: space-between;
    max-width: 300px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 300ms;

    &.displayed {
      visibility: visible;
      opacity: 1;
      transition: opacity 300ms;
    }
  }
}
