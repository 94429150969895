.changelog-settings__container {
  margin: -2.5rem;
  .settings__heading {
    //margin: -2.5rem;
    margin-bottom: 0;
    padding: 2.5rem 2.5rem 0 2.5rem;
  }

  .changelog-table {
    grid-template-columns:
      [selector] 64px
      [browser] 100px
      [key] minmax(max-content, 100px)
      [field] minmax(max-content, 100px)
      [oldValue] 200px
      [newValue] 200px
      [user] minmax(120px, max-content)
      [changeTime] minmax(200px, max-content)
      [revertable] minmax(64px, 1fr);

    .table-cell__link {
      color: $brand-darkened;
      text-decoration: underline;
    }

    .changelog-table-user {
      text-overflow: ellipsis;

      .table_cell--item {
        display: flex;
        flex-direction: row;
        width: 100%;
      }
      .username-column {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

// Disabled rows
.changelog-table {
  .table_row.table-row__irrevertible td {
    background: #ecf0f2;
    color: $ui-05;
  }

  .table_row.table-row__irrevertible:hover td {
    background: #ecf0f2;
  }

  .table_row.table-row__irrevertible .checkbox-container {
    pointer-events: none;
  }

  .table_head {
    .changelog-table-revertable {
      // display none breaks layout due to use of css grids
      // display: none;
      opacity: 0;
      pointer-events: none;
    }
  }
}

// Icons
.changelog-table {
  .changelog-table-revertable {
    justify-content: flex-end;
  }

  .table-icon__revertable {
    color: $brand-darkened;
    cursor: pointer;
    .mosaic-button.standard {
      min-width: unset;
      svg {
        color: $brand-darkened;
        fill: $brand-darkened;
      }

      &:hover {
        background: transparent;
      }
    }
  }
  .table-icon__irrevertible {
    color: $text-02;
  }
}

.changelog-modal {
  width: 748px;
}
