.aircraft-assignments-settings__container {
  margin: -2.5rem;
  .settings__heading {
    //margin: -2.5rem;
    margin-bottom: 0;
    padding: 2.5rem 2.5rem 0 2.5rem;
  }

  .aircraft-assignments-table {
    grid-template-columns:
      [selector] 64px
      [model] 100px
      [engine] minmax(max-content, 100px)
      [airline] minmax(max-content, 100px)
      [modelingAircraftId] minmax(max-content, 100px)
      [numberOfFlights] minmax(max-content, 100px)
      [source] minmax(64px, 1fr);

    .table-cell__link {
      color: $brand-darkened;
      text-decoration: underline;
    }

    .aircraft-assignments-table-user {
      text-overflow: ellipsis;

      .table_cell--item {
        display: flex;
        flex-direction: row;
        width: 100%;
      }
      .username-column {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

// Icons
.aircraft-assignments-table {
  .aircraft-assignments-table-numberOfFlights {
    justify-content: flex-end;
    text-align: right;
  }
  .aircraft-assignments-table-source {
    justify-content: flex-end;
  }

  .table-icon__source {
    color: $ui-04;

    .mosaic-button.standard {
      min-width: unset;
      svg {
        color: $ui-04;
        fill: $ui-04;
      }

      &:hover {
        background: transparent;
      }
    }
  }
}

.aircraft-assignments-modal {
  width: 748px;
}
