.emission-factor-mode {
  display: flex;
  align-items: center;
  margin-bottom: $spacing-md;
  margin-top: $spacing-xs;
}

.emission-factor-content-container {
  margin-top: $spacing-md;
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: auto;
}

.single-emission-factor-text-box {
  display: flex;
}

.multiple-emission-factors-text-box-holder {
  display: flex;
  flex-wrap: wrap;
}

.multiple-emission-factors-header {
  font-size: 14px;
  line-height: $spacing-xl;
  font-weight: 600;
}

.multiple-emission-factors-sub-header {
  display: flex;
  justify-content: space-between;
  margin-top: $spacing-md;
  margin-bottom: $spacing-md;
  flex-wrap: wrap;
}

.multiple-emission-table {
  flex: auto;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .table_wrapper {
    padding: 0px !important;
  }
}

.table.fuel-types-table {
  grid-template-columns:
    [checkbox] 1fr
    [airline] 2fr
    [aircraft-engine] 3fr
    [co2-emission-factor] 4fr
    [percOfFlights] 3fr
    [comment] 2fr;
}
