@use 'vars.module.scss';
@import '~@ems/client-design-system/scss/styles.scss';

html,
body,
#root {
  height: 100%;
}

:root {
  --severity-width: min-content;
  --severity-padding: 32px;
  --severity-thin-padding: 20px;
  --candidates-width: minmax(125px, max-content);
  --candidates-overflow: default;
  --status-width: minmax(90px, max-content);
  --status-thin-width: minmax(65px, max-content);
}

@import './airtrak';
@import './complaints';
@import './dashboard';
@import './infringements';
@import './layout';
@import './noiseEvents';
@import './operations';
@import './page-header';
@import './reports';
@import './scenarioGeneration';
@import './settings_aircraft_mappings';
@import './settings_fleet_mix';
@import './settings_fuel_types';
@import './settings_general';
@import './settings_infringementrules';
@import './settings_load_factors';
@import './settings_manage_configurations';
@import './settings_map-panel';
@import './settings_spatial_features';
@import './settings_time_in_modes';
@import './settings_changelogs';
@import './settings_aircraft_assignments';
@import './settings_aircraft_noise_contours';
@import './settings';
@import './table';
@import './userHelp';
@import './settings_locationmanagement';
// Components
@import 'src/components/CorrelatedEventCollapse/correlatedEventCollapse';
@import 'src/components/Navbar/styles';
@import 'src/components/LoadMoreBar/styles';
@import 'src/components/MapSelectionArea/styles';
@import 'src/components/AMSLPopup/styles';
@import 'src/components/PageSkeleton/styles';
@import 'src/components/Pill/styles';
