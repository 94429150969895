.load-factors-header {
  @extend .font-styles;
  font-weight: 600;
  font-size: 14px;
  line-height: $spacing-lg;
  margin-bottom: 20px;
}

.loadfactor-models-container {
  overflow: auto;
  flex: auto;
  display: flex;
  flex-direction: column;
}

.loadfactor-models-header {
  @extend .aircraft-models-header;
  margin-left: 0px;
}

.load-factors-table {
  grid-template-columns: repeat(9, 1fr);
}
