.reports-regular-table {
  grid-template-columns:
    [report] minmax(350px, max-content)
    [time] minmax(45px, max-content)
    [period] auto
    [download] minmax(120px, max-content);
}

.reports-table {
  @extend .reports-regular-table;

  .table_cell--item {
    button {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .hover-only {
    opacity: 0;
  }

  .cell-type {
    &--time {
      color: $time-color;
    }
  }

  &.table--loading .table_cell:hover .hover-only {
    opacity: 0;
  }

  .reports_downloadlink {
    padding: 0 2px;
  }
}
