@import 'vars.module.scss';
$map-skeleton-bg: $ui-03 !default;

.spatial-features__heading {
  display: flex;
  justify-content: space-between;
  padding: 2.5rem;

  .page-header {
    width: auto;
    margin: 0;

    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.83;
    letter-spacing: 0.07px;
  }
}

.spatial-feature__title {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  font-size: 1.125rem;
  line-height: 1.25;
  font-weight: 600;
}

.spatial-features-table {
  .table_cell--item {
    button {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  .grid-cell--title {
    padding: 0;
  }
  .sort-icon {
    position: relative;
    left: 0px;
  }

  .hover-only {
    opacity: 0;
  }

  .feature_link {
    color: $brand-darkened;
    text-decoration: none;
    cursor: pointer;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
    font-weight: bold;
  }

  .table_cell--item {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &.table--loading .table_cell:hover .hover-only {
    opacity: 0;
  }

  grid-template-columns:
    [selector] 64px
    [feature] minmax(100px, 2fr)
    [type] minmax(max-content, 100px)
    [lastUpdated] minmax(100px, max-content);
}

// View/Add and edit - spatial feature
.spatial-feature__detail {
  padding: 0 2rem 0 2rem;

  .dropdown_button .dropdown_button--placeholder {
    font-style: normal;
  }
  .spatial-feature__card {
    padding: 1rem 3rem 1rem 3rem;
    margin: 1em 1em 0 0;

    .input-wrapper {
      margin-bottom: 1.875rem;
    }
    .spatial-feature__form-field {
      margin-bottom: 0px;
    }
    // View feature
    .feature__field {
      padding: 1em 0 1em 0;
    }
    .spatial-feature__times {
      display: flex;
      .feature__field {
        margin-right: 3rem;
      }
    }
  }

  .spatial-feature__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 2em;
    .footer__action-buttons {
      .footer__button {
        margin-right: 1em;
        min-width: 4rem;
      }
    }
  }
}

.page-count {
  color: $text-02;
  font-size: $spacing-sm;
}
